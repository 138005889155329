'use strict';

function image() {
	var $homeImage = $('.main-image'),
		homeImagePath = $homeImage.css('background-image'),
		images = [
			'url(img/home/home_v3_group2.jpg)',
			'url(img/home/home_v3_flamenco.jpg)',
			'url(img/home/home_v3_group3.jpg)',
			'url(img/home/home_v3_dancers.jpg)',
			'url(img/home/home_v3_eddy_and_george.jpg)',
			//'url(img/home/home_v3_group1.jpg)',
			'url(img/home/home_v3_carnaval.jpg)'
		],
		imagesCount = 0,
		currentImageID = 0, // array key for image that is currently visible
		nextImage = null,
		opacity = 100,
		scale = 1,
		delay = 6000,
		loopLimit = 5, // number of times to go thru image array
		currentLoop = 0,
		$holder = $('<div class="holder"></div>'),
		$currentImage = $('<div class="currentImage"></div>'),
		timerID = null,
		paused = false,
		button = {
			play: '<span class="pausePlayButtonText">Play Slideshow</span> <i class="fas fa-play-circle  fa-2x"></i>',
			pause: '<span class="pausePlayButtonText">Pause Slideshow</span> <i class="fas fa-pause-circle fa-2x"></i>'
		},
		buttonHoverDefer = false;

	images.push(homeImagePath);
	imagesCount = images.length;
	nextImage = images[currentImageID];

	$currentImage.css('background-image', homeImagePath);
	$homeImage.find('.page-title-container').prepend('<a href="#" id="playPause">'+button.pause+'</a>');
	$homeImage.append($currentImage);
	$homeImage.css('background-image', 'none');

	$homeImage.append($holder);
	$holder.css('background-image', nextImage);

	function imageTransition() {
		if (currentLoop === loopLimit) {
			currentLoop = 0;
			paused = true;
			$('#playPause').html(button.play);
			return;
		}
		if (paused) {
			return;
		} else {
			if(opacity > 0) {
				opacity -= 0.5;
				scale += 0.001;
				$currentImage.css('opacity', opacity/100);
				$currentImage.css('transform', 'scale('+scale+')');
				window.requestAnimationFrame(imageTransition);
			} else {
				// we're done with this one
				$currentImage.css('background-image', nextImage);
				$currentImage.css('opacity', 1);
				scale = 1;
				$currentImage.css('transform', '');
				// increment the current image ID, rolling over to zero if needed 
				currentImageID = (currentImageID < imagesCount-1) ? currentImageID+1 : 0;
				if (currentImageID === 0) {
					currentLoop += 1;
				}
				nextImage = images[currentImageID];
				$holder.css('background-image', nextImage);
				opacity = 100;
				timerID = window.setTimeout(function() {
					window.requestAnimationFrame(imageTransition);
				}, delay);
			}
		}
	}

	// Kick off the slideshow
	timerID = window.setTimeout(function() {
		window.requestAnimationFrame(imageTransition);
	}, delay);

	// Event handlers
	$('#playPause').on('click', function(e) {
		e.preventDefault();
		var $targ = $(e.currentTarget);
		buttonHoverDefer = true;
		if (paused) {
			paused = false;
			$targ.html(button.pause);
			window.requestAnimationFrame(imageTransition);
		} else {
			window.clearTimeout(timerID);
			paused = true;
			$targ.html(button.play);
		}
	});
	$('#playPause').on('mouseenter', function(e) {
		if (!buttonHoverDefer) {
			var $targ = $(e.currentTarget),
				$text = $targ.find('.pausePlayButtonText');
			$text.addClass('shown');
		}
	});
	$('#playPause').on('mouseleave', function(e) {
		if (!buttonHoverDefer) {
			var $targ = $(e.currentTarget),
				$text = $targ.find('.pausePlayButtonText');
			$text.removeClass('shown');
		}
		buttonHoverDefer = false;
	});
}



if (typeof $ === 'function') {
	$(document).ready(function() {

		if ($('#sunMsg').length === 1) {
			var $sun = $('#sunMsg'),
				msg = $sun.data('title'),
				$popupHtml = $('<span id="sunPopup"></span>');

			$sun.on('mouseenter', function(e) {
				e.preventDefault();
				$sun.append($popupHtml);
				$('#sunPopup').animate({
					height: "24px",
					padding: '12px'
				},100, function() {
					$('#sunPopup').text(msg);
				});
			});
			$sun.on('mouseleave', function(e) {
				//$('#sunPopup').remove();
				$('#sunPopup').animate({
					height: "0",
					padding: '0'
				},100, function() {
					$('#sunPopup').remove();
				});
			});
		}

		//   =========================================    extra content callout behavior
		var $delExtraToggle = $('.extra-toggle');

		if ($delExtraToggle.length > 0) {
			$delExtraToggle.on('click', function(e) {
				e.preventDefault();
				var $targ = $(e.currentTarget),
					$content = $targ.closest('.extra-outer').find('.extra-content');

				$content.toggleClass('expanded');

				if ($content.hasClass('expanded')) {
					$targ.text('Hide Options');
				} else {
					$targ.text('Show More Options');
				}
			})
		}

		//   ========================================    home page images

		if ($('.page-home').length === 1) {
			image();
		} // end home page image

	}); // end document.ready
} // end if $ === function

